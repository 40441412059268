const Urls = {
  DOMAIN: 'http://localhost:3000',
  AUTH_API_PATH: 'api/frontend/customer/auth',
  GET_USER_API: 'api/frontend/api/customer/v2/vnp_customer_info',
  ADDRESS_LIST_API: 'addresses/customer',
  COMMON_API_PATH_ADDRESS: 'addresses',
  TABLE_STATE_API: 'api/admin/save/column/settings/new-tables',
  INVOICE_API: 'invoice',
  GET_INVOICE_LIST_API: 'invoice/customer',
  ORDER_API: 'b2c/order',
  ORDER_INFO_API: 'order/info',
  ORDER_REPORT_API: 'order/info/report',
  ORDER_SIGNATURE_API: 'order/info/signatures',
  ORDER_ATTACHMENTS_API: 'order/info/attachments',
  SEND_TRACKING_LINK_API: 'api/order/send-tracking-mail/customer',
  ORDER_TRACKING_API: 'order/info/trackingNumber',
  ORDER_COLUMN_STATE_API: 'api/frontend/customer/save_column_state/orderlist',
  ORDER_COLUMN_VISIBLE_API: 'api/frontend/customer/save_column_state/selectedColumnGridOrder',
  FAVOURITE_lOCATION_API: 'addresses/customer/favorite',
  GET_PACKAGES_API: 'api/frontend/api/customer/v2/packages',
  GET_SETTINGS_API: 'api/frontend/api/customer/v2/settings',
  SAVE_DRAFT_API: 'order/draft',
  REFRESH_PRICE_API: 'quote/priceRefresh',
  QUOTE_API: 'quote',
  QUOTE_LEAD_EMAIL_API: 'quote/sendLeadEmail',
  UNSUBSCRIBE_MAIL_API: 'api/unsubscribe',
  PAYMENT_INTENT_API: 'order/payment/createPaymentIntent'
};

export default Urls;
