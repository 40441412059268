import React, { useEffect, useRef, useState } from 'react';
import './parcelDetails.scss';
import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import ParcelDetailsForm from '../../../../Components/Order/Entry/parcelDetail/parcelDetailsForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPackageDescription,
  setPackagesIdArrayInStore,
  // setPickupDateAndTime,
} from '../../../../Store/Reducers/Order/orderSlice';
import {
  createIndexArr,
  createMultiplePackagesArray,
  reMaskFormValues,
} from '../../../../Util/commonUtility';

const ParcelDetails = ({ packages, form, datePickerForm }) => {
  // const [datePickerValue, setDatePickerValue] = useState();
  const [packagesIdArray, setPackagesIdArray] = useState([0]);
  const [dynamicDescription, setDynamicDescription] = useState('');
  const [packagesArr, setPackagesArr] = useState([]);
  const { packagesIdArrayInStore } = useSelector(state => state.OrderSlice);
  const { memorizeFieldsValues } = useSelector(state => state.AddressSlice);
  const dispatch = useDispatch();

  const isObjectBlank = obj => {
    for (const key in obj) {
      if (
        obj[key] === 'Select Package' ||
        obj[key] === 1 ||
        obj[key] === undefined ||
        obj[key] === ''
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const QUANTITY_LIMIT = 1000;

  useEffect(() => {
    if (memorizeFieldsValues?.packages?.length > 0) {
      //if edit draft
      setPackagesIdArray(createIndexArr(memorizeFieldsValues?.packages));
      form.resetFields();
      form.setFieldsValue(reMaskFormValues(memorizeFieldsValues?.packages));
      setPackagesArr(
        createMultiplePackagesArray(
          reMaskFormValues(memorizeFieldsValues?.packages)
        )
      );
      return;
    } else if (packagesIdArrayInStore.length > 1) {
      if (isObjectBlank(form.getFieldsValue())) {
        setPackagesIdArray([0]);
        return;
      }
      setPackagesIdArray(packagesIdArrayInStore);
    }
    setPackagesArr(createMultiplePackagesArray(form.getFieldValue()));
  }, []);
  useEffect(() => {
    return () => {
      // memorize packagesIdArray on unmount
      dispatch(setPackagesIdArrayInStore(packagesIdArray));
    };
  }, [packagesIdArray]);

  const deletePackage = id => {
    //delete fields value by id
    form.resetFields([
      `package${id}`,
      `combinedWeight${id}`,
      `packageHeight${id}`,
      `packageWidth${id}`,
      `packageLength${id}`,
      `packageQuantity${id}`,
      `Description${id}`,
    ]);
    form.setFieldsValue({ [`packageQuantity${id}`]: 1 });
    const arrayOfIndex = packagesIdArray.filter(pkgId => pkgId !== id);
    if (packagesIdArray.length > 1) {
      //new packages array ( get field value again and set as Package array )
      const newPackagesArr = createMultiplePackagesArray(form.getFieldValue());
      setPackagesIdArray(arrayOfIndex);
      setPackagesArr(newPackagesArr);
    } else if (packagesIdArray.length === 1) {
      const newPackagesArr = createMultiplePackagesArray(form.getFieldValue());
      setPackagesArr(newPackagesArr);
    }  
  };  

  const packageFormRef = useRef(null);
  const [initForm, setInitForm] = useState(true);

  useEffect(() => {
    let id = packagesIdArray[packagesIdArray.length - 1];
    if (
      packagesIdArray.length <= 1 &&
      form.getFieldValue(`package${id}`) === 'Select Package'
    ) {
      // package length is only 1
      setInitForm(true);
    } else {
      setInitForm(false);
    }
  }, [packagesIdArray, deletePackage]);


  const addPackagesHandler = (pkg) => {
    let id = packagesIdArray[packagesIdArray.length - 1];
    if (initForm && form.getFieldValue(`package${id}`) === 'Select Package') {
      // when package is not selected (default package)
      form.setFieldsValue({
        // setting fields value as per field name
        [`package${id}`]: pkg?.PackagingType,
        [`combinedWeight${id}`]: pkg?.Weight,
        [`packageLength${id}`]: pkg?.Length,
        [`packageWidth${id}`]: pkg?.Width,
        [`packageHeight${id}`]: pkg?.Height,
      });  
      packageFormRef.current.onPackageSelectHandler(pkg, initForm);
      setInitForm(false);
      return;
    }  
    packageFormRef.current.onPackageSelectHandler(pkg, initForm);
    setPackagesIdArray(pre => [...packagesIdArray, pre[pre.length - 1] + 1]); // adding 1 in last element for create id
  };  

  useEffect(() => {
    setDynamicDescription(dynamicPackageDescription());
  }, [packagesArr, packagesIdArray]);

  const calculatTotalQuantity = () => {
    return packagesArr.reduce((total, pkg) => total + pkg?.Qty, 0);
  };

  const dynamicPackageDescription = () => {
    let str = '';
    if (packagesArr[0]?.PackagingType && packagesArr[0]?.Qty) {
      str = `${packagesArr[0]?.Qty}  ${packagesArr[0]?.PackagingType}` || '';
      for (let i = 1; i < packagesArr.length; i++) {
        str = ` ${str} + ${packagesArr[i]?.Qty} ${packagesArr[i]?.PackagingType}`;
      }
      const descripition = `${str} = total ${calculatTotalQuantity()}`;
      dispatch(setPackageDescription(descripition));
      return descripition;
    }
  };

  const disabledDate = current => {
    const today = dayjs().startOf('day');
    const oneMonthLater = dayjs().add(1, 'month').endOf('day');
    return (
      current && (current.isBefore(today) || current.isAfter(oneMonthLater))
    );
  };

  return (
    <div className='parcel-details-container'>
      <div className='select-date-time-wrapper'>
        <div className='label-wrapper'>
          <span>Select Date & Time</span>
        </div>
        <div className='date-picker-wrapper'>
          <div className='align-wrapper'>
            <label htmlFor='date-picker'>Choose Pick-up Time</label>
            <Form form={datePickerForm} name='packages'>
              <Form.Item
                noStyle
                name={'date-time'}
              >
                <DatePicker
                  showTime
                  name='dateTime'
                  id='date-picker'
                  // value={datePickerValue}
                  format={'DD/MM/YYYY, h:mm a'}
                  // onOk={setDateAndTime}
                  defaultValue={dayjs()}
                  popupClassName='datepicker-popup'
                  // onSelect={setDateAndTime}
                  className='parcel-date-picker-input'
                  placeholder='Date & Time'
                  placement='bottomLeft'
                  allowClear={false}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className='packages-details-wrapper'>
        <div className='label-wrapper'>
          <span>Package Details</span>
        </div>
        <div className='add-package-btn-wrapper'>
          <span>{packagesArr.length > 0 && dynamicDescription}</span>
          <div className='pkg-btn-wrapper'>
            {packages?.map(pkg => (
              <>
                <button
                  key={pkg?.package_id}
                  className='package-add-btn'
                  onClick={() => addPackagesHandler(pkg)}
                >
                  {`Add ${pkg?.PackagingType}`}
                  <span className='btn-outline'>
                    <span className='pi pi-plus'></span>
                  </span>
                </button>
              </>
            ))}
          </div>
        </div>
        <div className='all-pkg-wrapper'>
        {packagesIdArray.map(id => (
          <ParcelDetailsForm
            key={id}
            form={form}
            id={id}
            ref={packageFormRef}
            packages={packages}
            deletePackage={deletePackage}
            setPackagesArr={setPackagesArr}
            setDynamicDescription={setDynamicDescription}
            dynamicPackageDescription={dynamicPackageDescription}
            QUANTITY_LIMIT={QUANTITY_LIMIT}
          />
        ))}
        </div>
      </div>
    </div>
  );
};

export default ParcelDetails;
