import { Form, Input, Modal, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import './orderEntry.scss';
import AddressDetails from './addressDetails/addressDetails';
import ParcelDetails from './parcelDetails/parcelDetails';
import SelectShipment from './selectShipment/SelectShipment';
import {
  locationGreySvg,
  parcelBlueSvg,
  parcelGreySvg,
  reviewBlueSvg,
  reviewGreySvg,
  shipMentBlueSvg,
  shipmentGreySvg,
  paymentBlueSvg,
  paymentGreySvg,
  piggyBankSvg,
} from '../../../Resource/images';
import {
  createMultiplePackagesArray,
  isObjectBlank,
  manipulateString,
  openNotification,
} from '../../../Util/commonUtility';
import {
  getPackages,
  getSettings,
  placeOrderApi,
  quoteApi,
  sendLeadMail,
} from '../../../Store/Action/order/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMemorizeFieldsValues,
  setPlaceOrderSteperDetails,
} from '../../../Store/Reducers/Address/AddressSlice';
import {
  setMaskedPackages,
  setQuoteData,
  setQuoteSubmitted,
  setShowQuote,
} from '../../../Store/Reducers/Order/orderSlice';
import dayjs from 'dayjs';
import { commonConfig } from '../../../Config/config';
import Review from '../review/review';
import Payment from './payment/payment';
const OrderEntry = () => {
  const [addressDetailsFormInstance] = Form.useForm();
  const [parcelDetailsFormInstance] = Form.useForm();
  const [B2CNotificationFormInstance] = Form.useForm();
  const [datePickerForm] = Form.useForm();
  const [changedPriceMod, setChangedPriceMod] = useState(false);
  const [selectedService, setSelectedServices] = useState({});
  const [isOrderSuccessfullyPlaced, setIsOrderSuccessfullyPlaced] =
    useState(false);
  const [leadEmailPopup, setLeadEmailPopup] = useState(false);
  const {
    packages,
    quoteSubmitted,
    showQuote,
    quoteApiDataTemp,
    settings,
    packageDescription,
    quoteData,
  } = useSelector(state => state.OrderSlice);
  const { tax } = settings;
  const { placeOrderSteperDetails, memorizeFieldsValues } = useSelector(
    state => state.AddressSlice
  );
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [leadEmailForm] = Form.useForm();

  useEffect(() => {
    dispatch(getPackages());
    dispatch(getSettings());
  }, []);

  const customDot = (dot, { title }) => {
    const setIcons = () => {
      switch (title) {
        case 'Address Details':
          return (
            <div className='ant-steps-icon-dot'>
              <img src={locationGreySvg} />
            </div>
          );
        case 'Parcel Details':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 1 ? (
                <img src={parcelBlueSvg} style={{ margin: '4px 1px 0 0' }} />
              ) : (
                <img src={parcelGreySvg} style={{ margin: '4px 1px 0 0' }} />
              )}
            </div>
          );
        case 'Best Rates':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 2 ? (
                <img src={shipMentBlueSvg} style={{ marginTop: '7px' }} />
              ) : (
                <img src={shipmentGreySvg} style={{ marginTop: '7px' }} />
              )}
            </div>
          );
        case 'Review':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 3 ? (
                <img src={reviewBlueSvg} style={{ marginTop: '2px' }} />
              ) : (
                <img src={reviewGreySvg} style={{ marginTop: '2px' }} />
              )}
            </div>
          );
        case 'Payment':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 4 ? (
                <img src={paymentBlueSvg} style={{ marginTop: '2px' }} />
              ) : (
                <img src={paymentGreySvg} style={{ marginTop: '7px' }} />
              )}
            </div>
          );
        default:
          return '';
      }
    };
    return setIcons();
  };
  const packagesArray = createMultiplePackagesArray(
    parcelDetailsFormInstance.getFieldValue()
  );

  const placeOrder = async () => {
    const B2CNotificationFormData = memorizeFieldsValues.B2CNotificationState;
    const B2CNotificationPayload = {
      IsSenderNotification: true,
      IsRecepientNotification: true,
      NotificationSetting: {
        SenderName: B2CNotificationFormData.SenderName || '',
        SenderPhone: B2CNotificationFormData.SenderPhone || '',
        SenderEmail: B2CNotificationFormData.SenderEmail || '',
        RecepientName: B2CNotificationFormData.RecepientName || '',
        RecepientEmail: B2CNotificationFormData.RecepientEmail || '',
        RecepientPhone: B2CNotificationFormData.RecepientPhone || '',
      },
      B2CPaymentData: '',
    };

    const CollectionLocation = {
      ...placeOrderSteperDetails.CollectionLocation,
      AddressLine2: B2CNotificationFormData.addressLine2ForSender || '',
    };
    const DeliveryLocation = {
      ...placeOrderSteperDetails.DeliveryLocation,
      AddressLine2: B2CNotificationFormData.addressLine2ForRecipient || '',
    };

    const maskedObj = {
      CollectionLocation,
      DeliveryLocation,
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      Quote: { ...selectedService.quote },
      Packages: packagesArray,
      TaxArray: tax.Taxes,
      Description: packageDescription,
      SelectedProviderType: selectedService?.parentProviderName || 'DLVRD',
      SelectedProviderId: selectedService?.parentProviderId || '',
      B2COrderObject: B2CNotificationPayload,
      IsB2COrder: true,
      isFromOutside: selectedService?.isFromOutside || false,
    };
    
    dispatch(
      placeOrderApi({
        data: maskedObj,
        setPage: setCurrent,
        setIsOrderSuccessfullyPlaced,
      })
    );
  };

  const steps = [
    {
      title: 'Address Details',
      content: <AddressDetails form={addressDetailsFormInstance} />,
    },
    {
      title: 'Parcel Details',
      content: (
        <ParcelDetails
          packages={packages}
          form={parcelDetailsFormInstance}
          datePickerForm={datePickerForm}
        />
      ),
    },
    {
      title: 'Best Rates',
      content: (
        <SelectShipment
          changedPriceMod={changedPriceMod}
          setChangedPriceMod={setChangedPriceMod}
          selectedService={selectedService}
          setSelectedServices={setSelectedServices}
        />
      ),
    },
    {
      title: 'Review',
      content: (
        <Review
          selectedService={selectedService}
          tax={tax}
          details={placeOrderSteperDetails}
          packagesArray={packagesArray}
          B2CNotificationFormInstance={B2CNotificationFormInstance}
          isOrderSuccessfullyPlaced={isOrderSuccessfullyPlaced}
        />
      ),
    },
    {
      title: 'Payment',
      content: (
        <Payment placeOrder={placeOrder} selectedService={selectedService} />
      ),
    },
  ];

  const validateErrorFields = errorArr => {
    return isObjectBlank(quoteApiDataTemp?.CollectionLocation)
      ? openNotification('error', 'Error', 'Please select pickup location')
      : isObjectBlank(quoteApiDataTemp?.DeliveryLocation)
      ? openNotification('error', 'Error', 'Please select delivery location')
      : '';
  };

  const maskDataForQuoteApi = () => {
    const collectionQuoteData = {
      ...quoteApiDataTemp?.CollectionLocation,
      AddressLine1: manipulateString(
        quoteApiDataTemp?.CollectionLocation?.AddressLine1
      ),
    };
    const DeliveryQuoteData = {
      ...quoteApiDataTemp?.DeliveryLocation,
      AddressLine1: manipulateString(
        quoteApiDataTemp?.DeliveryLocation?.AddressLine1
      ),
    };
    const userId = localStorage.getItem('userId');
    return {
      CollectionLocation: {
        ...collectionQuoteData,
        CompanyName: '',
        ContactName: '',
        AddressLine2: '',
        Comments: '',
        Email: '',
        Phone: '',
        PhoneExt: '',
      },
      DeliveryLocation: {
        ...DeliveryQuoteData,
        CompanyName: '',
        ContactName: '',
        AddressLine2: '',
        Comments: '',
        Email: '',
        Phone: '',
        PhoneExt: '',
      },
      CustomerId: userId,
      CustomerOnTimeId: commonConfig.CUSTOMER_ONTIME_ID,
    };
  };

  const next = async () => {
    //create diffrent functions
    try {
      if (current === 0) {
        const validate = await addressDetailsFormInstance.validateFields();
        const addressDetailsData = addressDetailsFormInstance.getFieldValue();

        dispatch(
          setMemorizeFieldsValues({
            ...memorizeFieldsValues,
            ...addressDetailsData,
          })
        );
        dispatch(setPlaceOrderSteperDetails(maskDataForQuoteApi()));
      }

      if (current === 1) {
        await parcelDetailsFormInstance.validateFields();
        const leadEmail = memorizeFieldsValues?.userEmail;
        const localpackagesArray = createMultiplePackagesArray(
          parcelDetailsFormInstance.getFieldValue()
        );
        if (localpackagesArray.length <= 0) return;
        if (!leadEmail) {
          setLeadEmailPopup(true);
          return;
        }
        leadEmailHandler();
        return;
      }

      if (current === 2) {
        if (Object.keys(selectedService).length <= 0) {
          openNotification('error', 'Error', 'Please Select a Quote');
          return;
        }
      }

      if (current === 3) {
        try {
          await B2CNotificationFormInstance.validateFields();
          dispatch(
            setMemorizeFieldsValues({
              ...memorizeFieldsValues,
              B2CNotificationState: {
                ...B2CNotificationFormInstance.getFieldsValue(),
              },
            })
          );
        } catch (error) {
          return;
        }
      }

      setCurrent(current + 1);
    } catch (error) {
      if (current === 1) {
        return;
      }
      validateErrorFields(error.errorFields);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    if (current === 1) {
      const localpackagesArray = createMultiplePackagesArray(
        parcelDetailsFormInstance.getFieldValue()
      );
      dispatch(
        setMemorizeFieldsValues({
          ...memorizeFieldsValues,
          packages: localpackagesArray,
        })
      );
    }
    if (current === 2) {
      setSelectedServices({});
      B2CNotificationFormInstance.resetFields();
      dispatch(setQuoteData([]));
      dispatch(
        setMemorizeFieldsValues({
          ...memorizeFieldsValues,
          selectedService: null,
          B2CNotificationState: {},
        })
      );
    }
    if (current === 3) {
      dispatch(
        setMemorizeFieldsValues({
          ...memorizeFieldsValues,
          B2CNotificationState: {
            ...B2CNotificationFormInstance.getFieldsValue(),
          },
        })
      );
    }
  };

  const goBack = () => {
    setCurrent(0);
    dispatch(setQuoteData([]));
    dispatch(setShowQuote(false));
    dispatch(setQuoteSubmitted({ IsQuote: false }));
  };
  const redirectToContactUs = () => {
    window.location = commonConfig.redirectContactUsURL;
  };

  const leadEmailHandler = () => {
    const localpackagesArray = createMultiplePackagesArray(
      parcelDetailsFormInstance.getFieldValue()
    );
    const PickupDateTime = dayjs(
      datePickerForm.getFieldValue(['date-time'])
      ).toISOString();
    if (localpackagesArray.length <= 0) return;
      setCurrent(current + 1);
      
    const leadEmail = memorizeFieldsValues?.userEmail;
    const quoteObj = {
      ...placeOrderSteperDetails,
      Packages: localpackagesArray,
      UserEmail:
        leadEmailForm?.getFieldValue('email') ||
        memorizeFieldsValues?.userEmail,
      PickupDateTime,
    };

    dispatch(
      setMemorizeFieldsValues({
        ...memorizeFieldsValues,
        packages: localpackagesArray,
        userEmail:
          leadEmailForm?.getFieldValue('email') ||
          memorizeFieldsValues?.userEmail,
      })
    );
    dispatch(quoteApi({ data: quoteObj, placeOrderSteperDetails }));
    if (!leadEmail) {
      dispatch(sendLeadMail({ data: quoteObj }));
    }
    dispatch(setMaskedPackages(packagesArray));
    setLeadEmailPopup(false);
    leadEmailForm.resetFields();
  };

  const onCancel = () => {
    leadEmailForm.resetFields();
    setLeadEmailPopup(false);
  };
  return (
    <>
      <Modal
        prefixCls='email-modal'
        open={leadEmailPopup}
        onCancel={onCancel}
        footer={false}
      >
        <header>
          <div className='text-wrapper'>
            <h2>You are one step away from saving time and money</h2>
            <span>every time you ship with us</span>
          </div>
          <div className='img-wrapper'>
            <img src={piggyBankSvg} alt='piggy-bank' />
          </div>
        </header>
        <main className='email-form-wrapper'>
          <label htmlFor='lead-email' className='lead-email'>
            Enter email address
          </label>
          <Form
            name='lead-email-form'
            prefixCls='lead-form'
            onFinish={leadEmailHandler}
            form={leadEmailForm}
          >
            <Form.Item
              name={'email'}
              rules={[
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Please enter valid email',
                },
              ]}
            >
              <Input placeholder='Enter your email' id='lead-email' />
            </Form.Item>
            <button className='show-me-rates-btn' type='submit'>
              Show me rates
            </button>
          </Form>
          <p className='note'>
            Note: Final shipping cost may vary based on your full address
            details and/or any additional options that are selected such as
            insuring your shipment, requiring a signature, etc.
          </p>
        </main>
      </Modal>
      <div className='order-entry-container'>
        <main className='order-steper-wrapper'>
          <div className='order-steper-header' style={{ display: 'none' }}>
            <Steps
              current={current}
              labelPlacement='vertical'
              progressDot={customDot}
              items={steps}
              className='order-steps'
              responsive={false}
            />
          </div>
          <div
            className={`steper-content-container ${
              current === 4 && 'applyPadding'
            }`}
          >
            <header className='steper-heading'>
              {!isOrderSuccessfullyPlaced && <span>{current + 1}</span>}
              {isOrderSuccessfullyPlaced ? (
                <h3 className='order-placed-txt'>
                  Your order has been placed successfully
                </h3>
              ) : steps[current].title === 'Select Shipment' ? (
                'Select Shipment Partner'
              ) : (
                steps[current].title
              )}
            </header>
            <section className='steper-content-wrapper'>
              {steps[current].content}
            </section>
          </div>
          {isOrderSuccessfullyPlaced && (
            <button
              className='place-new-order-btn'
              onClick={() => window.location.reload()}
            >
              Place new order
            </button>
          )}
          {!isOrderSuccessfullyPlaced && (
            <footer
              className='steper-btn-container'
              style={{
                justifyContent: current > 0 ? 'space-between' : 'flex-end',
              }}
            >
              {current > 0 && (
                <button
                  onClick={() => (showQuote ? goBack() : prev())}
                  className={`save-draft-btn`}
                >
                  {showQuote ? 'Go Back' : 'Back'}
                </button>
              )}
              {current < steps.length - 1 && (
                <button
                  type='submit'
                  onClick={() => next()}
                  className={`steper-next-btn ${showQuote && 'hide'} ${
                    current === 2 &&
                    quoteData.length <= 0 &&
                    !showQuote &&
                    'hide'
                  }`}
                >
                  {current === 3 ? 'Pay Now' : 'Next'}
                </button>
              )}
              {current === steps.length - 1 && (
                <button
                  type='primary'
                  className={`save-draft-btn ${
                    quoteSubmitted?.IsQuote && 'hide'
                  }`}
                  onClick={redirectToContactUs}
                >
                  Contact us
                </button>
              )}
            </footer>
          )}
        </main>
      </div>
    </>
  );
};

export default OrderEntry;
