import React, { useEffect, useState } from 'react';
import './selectShipment.scss';
import ShipmentServices from '../../../../Components/Order/Entry/selectShipment/shipmentServices';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../Components/Order/Entry/selectShipment/shipment-services.scss';
import { submitQuote } from '../../../../Store/Action/order/orderAction';
import { Empty } from 'antd';
import { setShowQuote } from '../../../../Store/Reducers/Order/orderSlice';
import { commonConfig } from '../../../../Config/config';
import { setMemorizeFieldsValues } from '../../../../Store/Reducers/Address/AddressSlice';

const SelectShipment = ({ setSelectedServices }) => {
  const dispatch = useDispatch();
  const {
    quoteData,
    settings,
    showQuote,
    maskedPackages: Packages,
  } = useSelector(state => state.OrderSlice);
  const [shipmentServices, setShipmentServices] = useState([]);
  const { placeOrderSteperDetails, locationId, memorizeFieldsValues } =
    useSelector(state => state.AddressSlice);
  const { tax } = settings;

  useEffect(() => {
    if (showQuote) {
      setShipmentServices([]);
      return;
    }
        setShipmentServices(renderQuotes(quoteData));
  }, [showQuote, quoteData]);

  useEffect(() => {
    if (memorizeFieldsValues?.selectedService) {
      const selectedButton = document.getElementById(
        memorizeFieldsValues?.selectedService
      );
      selectedButton?.classList?.add('active');
    }
  }, [shipmentServices]);

  useEffect(() => {
    return () => {
      dispatch(setShowQuote(false));
    };
  }, []);

  const pickupWithId = {
    ...placeOrderSteperDetails.CollectionLocation,
    ID: locationId.pickupId,
    Zone: {},
  }; // create Common function for
  const deliveryWithId = {
    ...placeOrderSteperDetails.DeliveryLocation,
    ID: locationId.deliveryId,
    Zone: {},
  };

  const getQuoteSubmitted = () => {
    const maskedObj = {
      CollectionLocation: { ...pickupWithId },
      DeliveryLocation: { ...deliveryWithId },
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      Packages,
    };
    dispatch(submitQuote({ payload: maskedObj, bool: true }));
  };

  const selectShipmentHandler = (e, id, service, name) => {
        setSelectedServices(service);
    dispatch(
      setMemorizeFieldsValues({
        ...memorizeFieldsValues,
        selectedService: id,
      })
    );
    const btns = document.getElementsByClassName(`select-btn`);
    Array.from(btns).forEach(btn => {
      btn.id.includes(`${id}`)
        ? btn.classList.add('active')
        : btn.classList.remove('active');
    });
  };

  const renderQuotes = quoteArray => {
    let arr = [];
    const recursive = (quoteArray, name, id, isOutside) => {
      quoteArray?.forEach(quote => {
                if (quote.data) {
          quote.data.forEach(service => {
            if (service.isFromOutside === true) {
              recursive(
                service.data,
                quote.provider,
                quote.providerId,
                service.isFromOutside
              );
            } else {
              if (service.data) {
                recursive(service.data, quote.provider);
              } else {
                arr.push({
                  quote: service,
                  parentProviderName:
                    quote.provider === 'OnTime360' ? 'VNP' : quote.provider,
                  parentProviderId: quote.providerId,
                  isFromOutside: service.isFromOutside || false,
                });
              }
            }
          });
        } else {
          arr.push({
            quote,
            parentProviderName: name === 'OnTime360' ? 'VNP' : name,
            parentProviderId: id,
            isFromOutside: isOutside,
          });
        }
      });
    };

    recursive(quoteArray);
    return arr.flat(1);
  };

  return (
    <div className='shipment-container'>
      <header className='label-wrapper'>
        <span>Best Rates</span>
      </header>
      <main className='shipment-list-container'>
        <table className='shipment-table'>
          <thead>
            <tr>
              <th>Carrier Service</th>
              <th>Estimated Delivery</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shipmentServices?.length > 0 ? (
              shipmentServices?.map((service, i) => (
                <ShipmentServices
                  key={i}
                  service={service}
                  tax={tax}
                  selectShipmentHandler={selectShipmentHandler}
                />
              ))
            ) : //)
            shipmentServices.length <= 0 && !showQuote ? (
              <tr className='quote-row'>
                <td colSpan={4}>
                  <Empty
                    description={'No Service Available!'}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </td>
              </tr>
            ) : showQuote === true ? (
              <tr className='quote-row'>
                <td colSpan={4} className='quote-container'>
                  {
                    <div className='quote-txt-wrapper'>
                      <p>
                        Standard Price Not Available For Selected &nbsp;
                        <span>
                          {
                            placeOrderSteperDetails?.CollectionLocation
                              ?.PostalCode
                          }
                        </span>
                        &nbsp; to &nbsp;
                        <span>
                          {placeOrderSteperDetails.DeliveryLocation?.PostalCode}
                        </span>
                      </p>
                      <section className='best-rates-info'>
                        Please contact{' '}
                        <span>{commonConfig.vnpContactNumber}</span> or{' '}
                        <span> {commonConfig.vnpEmail}</span> for discussing
                        business rates.
                      </section>
                    </div>
                  }
                </td>
              </tr>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </main>
    </div>
  );
};

export default SelectShipment;
