import { PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import './paymentCheckoutForm.scss';
import { WarningOutlined } from '@ant-design/icons';

export default function PaymentCheckoutForm({ placeOrder, calculatedPrice }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    await stripe
      .confirmPayment({
        elements,
        redirect: 'if_required',
      })
      .then(result => {
        if (result?.error) {
          setMessage(result?.error?.message);
        } else {
          setMessage('');
          placeOrder();
        }
        setIsProcessing(false);
      });
  };

  const onLoadError = error => {
    if (error) setError(true);
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' onLoadError={onLoadError} />
      {error ? (
        <div className='error-fallback'>
          <WarningOutlined style={{ fontSize: '5rem', color: 'red' }} />
          <h3>Something Went Wrong </h3>
        </div>
      ) : (
        <div className='payment-btn-wrapper'>
          <button
            disabled={isProcessing || !stripe || !elements}
            id='submit'
            className='payment-btn'
          >
            <span id='button-text'>
              {isProcessing ? 'Processing ... ' : `Pay $${calculatedPrice}`}
            </span>
          </button>
        </div>
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
