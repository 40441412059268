import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  hideAttchmentsLoader,
  hideSpinner,
  showAttchmentsLoader,
  showSpinner,
} from '../../Reducers/Spinner/spinnerSlice';
import {
  calculatePriceWithTax,
  checkHttpResponse,
  checkHttpStatusCode,
  openNotification,
} from '../../../Util/commonUtility';
import { commonError, orderNotFound } from '../../../Util/config';
import NGS_API from '../../../Api/ngs';
import Urls from '../../../Constant/urls';
import {
  setAttachments,
  setColSignatures,
  setDelivSignatures,
  setOrderInfo,
  setOrderList,
  setOrderVisibleGrid,
  setOrdersTrackingDetails,
  setPackages,
  setQuoteData,
  setQuoteSubmitted,
  setRecalculatedPrice,
  setReport,
  setSetting,
  setShowQuote,
} from '../../Reducers/Order/orderSlice';
import VNP_API from '../../../Api/api';
import { setPlaceOrderSteperDetails } from '../../Reducers/Address/AddressSlice';

export const getOrderList = createAsyncThunk(
  'getOrders',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderList(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderInfo = createAsyncThunk(
  'getOrderInfo',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_INFO_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderInfo(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderSignatures = createAsyncThunk(
  'getOrderSignatures',
  async ({ id, type }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(
        `${Urls.ORDER_SIGNATURE_API}/${id}/${type}`
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        if (type === 1) {
          dispatch(setDelivSignatures(response.data.data));
        } else {
          dispatch(setColSignatures(response.data.data));
        }
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderAttachments = createAsyncThunk(
  'getOrderAttachments',
  async (id, { dispatch }) => {
    try {
      dispatch(showAttchmentsLoader());
      const response = await NGS_API.get(`${Urls.ORDER_ATTACHMENTS_API}/${id}`);
      dispatch(hideAttchmentsLoader());

      if (checkHttpResponse(response)) {
        dispatch(setAttachments(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideAttchmentsLoader());
    }
  }
);

export const getOrderReport = createAsyncThunk(
  'getOrderReport',
  async (data, { dispatch }) => {
    const { url } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_REPORT_API}/${url}`, {
        responseType: 'arraybuffer',
      });
      let blobUrl = URL.createObjectURL(new Blob([response?.data]));

      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setReport(blobUrl));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const sendTrackingLink = createAsyncThunk(
  'sendTrackingLink',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.post(
        `${Urls.SEND_TRACKING_LINK_API}`,
        data
      );
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', 'Email has been sent.');
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const downloadReport = createAsyncThunk(
  'download',
  async (url, { dispatch }) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'blob.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);

export const getOrderTrackingDetails = createAsyncThunk(
  'getOrderTRackingDetails',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_TRACKING_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrdersTrackingDetails(response.data.data));
      } else {
        dispatch(setOrdersTrackingDetails(null));
        openNotification('error', 'Error', orderNotFound);
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getPackages = createAsyncThunk(
  'getPackages',
  async (_, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.get(`${Urls.GET_PACKAGES_API}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setPackages(response.data.payload));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);


export const quoteApi = createAsyncThunk(
  'quoteApi',
  async ({data, placeOrderSteperDetails}, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.QUOTE_API}`, data);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setQuoteData(response.data.data));
        dispatch(setPlaceOrderSteperDetails({
          ...placeOrderSteperDetails,
         CollectionLocation :{
          ...placeOrderSteperDetails?.CollectionLocation,
           AddressId: response?.data.locationData?.collectionAddressId || '',
         },
         DeliveryLocation:{
          ...placeOrderSteperDetails?.DeliveryLocation,
          AddressId: response?.data.locationData?.deliveryAddressId || '',
         }
        }));
      } else {
        dispatch(setShowQuote(true));
      }

    } catch (error) {
      dispatch(setQuoteData([]));
      dispatch(hideSpinner());
    }
  }
);

export const sendLeadMail = createAsyncThunk(
  'sendLeadMail',
  async ({data}, { dispatch }) => {
    try {
      const response = await NGS_API.post(`${Urls.QUOTE_LEAD_EMAIL_API}`, data);
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const getSettings = createAsyncThunk(
  'getSettings',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.get(`${Urls.GET_SETTINGS_API}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setSetting(response.data.payload));
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const recalculatePriceMod = createAsyncThunk(
  'recalculatePrice',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(
        `${Urls.REFRESH_PRICE_API}`,
        data?.data
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setRecalculatedPrice(response?.data?.data));
        openNotification(
          'success',
          'Success',
          `Price Fetched, New Price! ${
            calculatePriceWithTax(data?.tax, response?.data?.data, '')?.price
          }`
        );
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const submitQuote = createAsyncThunk(
  'submiteQuote',
  async (data, { dispatch }) => {
    const { payload, bool } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(
        `${Urls.SAVE_DRAFT_API}/${bool}`,
        payload
      );
      dispatch(hideSpinner());
      if (checkHttpStatusCode(response)) {
        dispatch(setQuoteSubmitted(response?.data?.data));
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const deleteDraft = createAsyncThunk(
  'deleteDraft',
  async ({ id, userId }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.delete(`${Urls.ORDER_API}/${id}/Draft`);
      if (checkHttpStatusCode(response)) {
        dispatch(getOrderList(userId));
      }
      dispatch(hideSpinner());
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const placeOrderApi = createAsyncThunk(
  'placeOrder',
  async ({ data, setPage, setIsOrderSuccessfullyPlaced }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.ORDER_API}`, data);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', 'Order Placed');
        setIsOrderSuccessfullyPlaced(true);
        setPage(3);
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);
