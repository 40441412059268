import React, { useEffect, useState } from 'react'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentCheckoutForm from '../../../../Components/Order/Entry/payment/paymentCheckoutForm/paymentCheckoutForm';
import { useSelector } from 'react-redux';
import { commonConfig } from '../../../../Config/config';
import NGS_API from '../../../../Api/ngs';
import Urls from '../../../../Constant/urls';
import { calculatePriceWithTax } from '../../../../Util/commonUtility';

const Payment = ({ placeOrder, selectedService }) => {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const { memorizeFieldsValues } = useSelector(state => state.AddressSlice);
    const { settings } = useSelector(state => state.OrderSlice);
    const { tax } = settings;

    const calculatedPrice = calculatePriceWithTax(tax, selectedService?.quote?.price, '').price || '0$'
  useEffect(() => {
      const  publishableKey  = commonConfig.publishableKey
      setStripePromise(loadStripe(publishableKey));
  }, []);

  const getPaymentIntent = async () => {
    try {
      const response = await NGS_API.get(`${Urls.PAYMENT_INTENT_API}/${calculatedPrice}`)
      setClientSecret(response?.data?.data?.clientSecret)
     } catch (error) {
     }
  }

  useEffect(() => {
  getPaymentIntent();
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentCheckoutForm placeOrder={placeOrder} calculatedPrice={calculatedPrice} clientSecret={clientSecret}/>
        </Elements>
      )}
    </>
  )
}

export default Payment