import { combineReducers } from 'redux';

import spinnerSlice from './Spinner/spinnerSlice';
import AddressSlice from './Address/AddressSlice';
import OrderSlice from './Order/orderSlice';


export default combineReducers({
  spinnerSlice,
  AddressSlice,
  OrderSlice,
});
