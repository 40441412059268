import React from 'react';
import './shipment-services.scss';
import moment from 'moment-timezone';

const ShipmentServices = ({ service, tax, selectShipmentHandler }) => {
  const formateDateASCanadaCenteralTime = () => {
    return moment(new Date(service?.quote?.deliveryDate))
      .tz('America/Montreal')
      .format(dateFormate);
  };

  const serviceName = `${service?.quote?.carrierName} / ${service?.quote?.serviceName}`;
  const dateFormate = 'hh:mm A, dddd, MMMM D, y';
  const formatedDate = formateDateASCanadaCenteralTime();

  return (
    <tr>
      <th>{serviceName}</th>
      <th>{formatedDate}</th>
      <th className='price'>
        ${service?.quote.price}
        CAD
      </th>
      <th>
        <button
          id={service?.quote?.quoteId}
          onClick={e =>
            selectShipmentHandler(e, service?.quote?.quoteId, service, serviceName)
          }
          className={`select-btn ${service?.quote?.quoteId}-btn`}
        >
          Select
        </button>
      </th>
    </tr>
  );
};

export default ShipmentServices;
