import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OrderEntryScreen from '../Screens/order/orderEntryScreen';


const AppRoute = () => {
  return (
    <>
      <Routes>
        <Route
          path={'/'}
          element={      
              <OrderEntryScreen />
          }
        />
        <Route path={'*'} element={<Navigate to={'/'}/>} />
      </Routes>
    </>
  );
};

export default AppRoute;
