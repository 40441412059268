import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import './address-details-pickup-delivery-form.scss';
import { Autocomplete } from '@react-google-maps/api';
import { googlePlaceDataMasking, isObjectBlank } from '../../../../Util/commonUtility';
import { useDispatch, useSelector } from 'react-redux';
import { setMemorizeFieldsValues } from '../../../../Store/Reducers/Address/AddressSlice';
import { setQuoteApiDataTemp } from '../../../../Store/Reducers/Order/orderSlice';
import { commonConfig } from '../../../../Config/config';

const AddressDetailsPickupDeliveryForm = props => {
  const { form, isPickup } = props;

  const dispatch = useDispatch();
  const { memorizeFieldsValues } = useSelector(state => state.AddressSlice);
  const { quoteApiDataTemp } = useSelector(state => state.OrderSlice);
  const [searchResult, setSearchResult] = useState('');
  const [googlePlaceApiData, setGooglePlaceApiData] = useState({});


  useEffect(() => {
    if (memorizeFieldsValues) {
      // autofill locations
      const {
        CollectionLocation,
        DeliveryLocation,
        pickupFormattedAddress,
        deliveryFormattedAddress,
      } = memorizeFieldsValues?.quoteApiDataTemp;
      setGooglePlaceApiData(
        isPickup
          ? {
              formattedAddress: pickupFormattedAddress,
              addressLine1: CollectionLocation?.AddressLine1,
              city: CollectionLocation?.City,
              state: CollectionLocation?.State,
              country: CollectionLocation?.Country,
              postalCode: CollectionLocation?.PostalCode,
              latitudeLongitude: CollectionLocation?.LatitudeLongitude,
            }
          : {
            formattedAddress: deliveryFormattedAddress,
              addressLine1: DeliveryLocation?.AddressLine1,
              city: DeliveryLocation?.City,
              state: DeliveryLocation?.State,
              country: DeliveryLocation?.Country,
              postalCode: DeliveryLocation?.PostalCode,
              latitudeLongitude: DeliveryLocation?.LatitudeLongitude,
            }
      );
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        setMemorizeFieldsValues({ ...memorizeFieldsValues, quoteApiDataTemp })
      );
    };
  }, [quoteApiDataTemp]);

  const onLoad = autocomplete => {
    setSearchResult(autocomplete);
  };

  useEffect(() => {
    dispatch(
      setQuoteApiDataTemp(
        isPickup
          ? {
              ...quoteApiDataTemp,
              pickupFormattedAddress: googlePlaceApiData?.formattedAddress,
              CollectionLocation: {
                AddressLine1: googlePlaceApiData?.addressLine1,
                City: googlePlaceApiData?.city,
                State: googlePlaceApiData?.state,
                Country: googlePlaceApiData?.country,
                PostalCode: googlePlaceApiData?.postalCode || '',
                LatitudeLongitude: googlePlaceApiData?.latitudeLongitude || '',
              },
            }
          : {
              ...quoteApiDataTemp,
              deliveryFormattedAddress: googlePlaceApiData?.formattedAddress,
              DeliveryLocation: {
                AddressLine1: googlePlaceApiData?.addressLine1,
                City: googlePlaceApiData?.city,
                State: googlePlaceApiData?.state,
                Country: googlePlaceApiData?.country,
                PostalCode: googlePlaceApiData?.postalCode || '',
                LatitudeLongitude: googlePlaceApiData?.latitudeLongitude || '',
              },
            }
      )
    );

    form.setFieldsValue(
      isPickup
        ? {
            addressLine1:
              googlePlaceApiData?.formattedAddress
          }
        : {
            addressLine1ForDelivery:
              googlePlaceApiData?.formattedAddress
          }
    );
  }, [googlePlaceApiData]);

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setGooglePlaceApiData(googlePlaceDataMasking(place));
    } else {
      alert('Please enter text');
    }
  };
  const center = commonConfig.centerLatLong;
  const radiusMultiplier = 1; // 1 = 100km

  const Bounds = {
    north: center?.lat + radiusMultiplier,
    south: center?.lat - radiusMultiplier,
    east: center?.lng + radiusMultiplier,
    west: center?.lng - radiusMultiplier,
  };
  var options = {
    bounds: center && Bounds,
    strictBounds: true,
  };

  const locationValidator = (_,value) => {
    if (!value) {
      return Promise.reject(new Error('Address line 1 is required'))
    }
    if (isPickup && isObjectBlank(quoteApiDataTemp?.CollectionLocation)) {
      return Promise.reject(new Error('Please select valid location'))
    }else if (isPickup && !quoteApiDataTemp?.CollectionLocation?.PostalCode){
      return Promise.reject(new Error('Postal code not found'))
    }
    else if(!isPickup && isObjectBlank(quoteApiDataTemp?.DeliveryLocation)){
      return Promise.reject(new Error('Please select valid location'))
    }
    else if (!isPickup && !quoteApiDataTemp?.DeliveryLocation?.PostalCode){
      return Promise.reject(new Error('Postal code not found'))
    }else{
      return Promise.resolve();
    }
  }

  const inputChangeHandler = (e) => {
   if (e.target.value) {
    if (isPickup) {
      dispatch(setQuoteApiDataTemp({...quoteApiDataTemp, CollectionLocation:null}))
    }
    else{
      dispatch(setQuoteApiDataTemp({...quoteApiDataTemp, DeliveryLocation:null}))
    }
   }
  }

  return (
    <div className='pickup-form'>
      <header>
        <span
          className={isPickup ? 'pickup-form-label' : 'delivery-form-label'}
        >
          <h3>{isPickup ? 'Pickup At' : 'Deliver To'}</h3>
        </span>
      </header>
      <div className='search-address-wrapper'>
        <Form
          form={form}
          className='address-details-form'
          // initialValues={initialValues}
        >
          <Autocomplete
            options={options}
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <Form.Item
              name={isPickup ? 'addressLine1' : 'addressLine1ForDelivery'}
              label={isPickup ? 'From' : 'To'}
              key={isPickup}
              required
              validateTrigger={['onChange', 'onPlaceChanged']}
              rules={[
                {
                  whitespace: true,
                  validator: locationValidator,
                 }
              ]}
            >
              <Input
                allowClear
                className='address-select'
                placeholder='Address line 1'
                onChange={inputChangeHandler}
                // className='popup-inputs'
              />
            </Form.Item>
          </Autocomplete>
        </Form>
      </div>
    </div>
  );
};

export default AddressDetailsPickupDeliveryForm;
