import { notification } from 'antd';
import { commonConfig } from '../Config/config';

export const openNotification = (type, msg, desc) => {
  notification.open({
    message: msg,
    type: type,
    duration: 3,
    description: desc,
  });
  notification.config({
    maxCount: 1,
  });
};

export const getCurrentAccessToken = () => {
  return localStorage.getItem('userAccessToken') || null;
};

export const redirectTo = () => {
  window.location = commonConfig.redirectToHome;
};

export const manipulateString = inputString => {
  if (inputString?.length >= 45) {
    return inputString?.substring(0, 47) + '...';
  } else {
    return inputString;
  }
};
const checkAddressString = (name, str) => {
  if (!str) {
    return name;
  }
  const result = str?.substring(0, name.length);
  return name === result ? str: `${name}, ${str}`;
};

export const googlePlaceDataMasking = data => {
  const { address_components, geometry, name } = data;
  const maskedObj = {};
  maskedObj.latitudeLongitude = `${geometry?.location?.lat()},${geometry?.location?.lng()}`;

  address_components?.forEach(element => {
    const elementType = element?.types?.[0];

    switch (elementType) {
      case 'administrative_area_level_2':
      case 'locality':
        maskedObj.city = element?.long_name || '';
        break;
      case 'country':
        maskedObj.country = element?.long_name || '';
        break;
      case 'postal_code':
      case 'postal_code_prefix':
        maskedObj.postalCode = element?.long_name || '';
        break;
      case 'administrative_area_level_1':
        maskedObj.state = element?.long_name || '';
        break;
    }
  });

  maskedObj.addressLine1 = name;
  maskedObj.formattedAddress = checkAddressString (name, data?.formatted_address || null)
  return maskedObj;
};

const HTTP_CODES = {
  SUCCESS: [200, 201, 202, 203],
  ERROR: [400, 401, 402, 403, 404, 500, 501],
};

export const checkHttpStatusCode = response => {
  if (HTTP_CODES?.SUCCESS?.includes(response?.status)) {
    return true;
  } else if (HTTP_CODES?.ERROR?.includes(response?.status)) {
    return false;
  }
};

export const checkHttpResponse = response => {
  if (checkHttpStatusCode(response) && checkIsValueNotNull(response)) {
    return true;
  } else {
    return false;
  }
};

export const checkIsValueNotNull = value => {
  if (value === null || undefined) {
    return false;
  } else {
    return true;
  }
};

export const createMultiplePackagesArray = obj => {
  let resultArray = [];
  for (let key in obj) {
    if (key === 'date-time') {
      continue;
    }
    let match = key?.match(/\d+/)[0]; // taking id from key name

    if (match) {
      let id = match[0];
      if (!resultArray[id]) {
        resultArray[id] = {};
      }
      resultArray[id][key] = obj[key];
    }
  }
  const maskedArray = resultArray
    ?.map((pkg, i) => {
      return {
        PackagingType: pkg[`package${i}`],
        Weight: pkg[`combinedWeight${i}`],
        Height: pkg[`packageHeight${i}`],
        Width: pkg[`packageWidth${i}`],
        Length: pkg[`packageLength${i}`],
        Qty: Number(pkg[`packageQuantity${i}`]),
        Unit: 'lb',
        Description: pkg[`Description${i}`] || '',
      };
    })
    ?.filter(pkg => {
      return pkg.PackagingType !== 'Select Package';
    })
    ?.filter(pkg => typeof pkg.PackagingType !== 'undefined');
  return maskedArray;
};

export const reMaskFormValues = arr => {
  let obj = {};
  for (let i = 0; i < arr?.length; i++) {
    const elm = arr[i];
    const curObj = {
      [`package${i}`]: elm.PackagingType,
      [`combinedWeight${i}`]: elm.Weight,
      [`packageHeight${i}`]: elm.Height,
      [`packageWidth${i}`]: elm.Width,
      [`packageLength${i}`]: elm.Length,
      [`packageQuantity${i}`]: elm.Qty,
      [`Description${i}`]: elm.Description || '',
    };
    obj = { ...obj, ...curObj };
  }
  return obj;
};

export const createIndexArr = arr => {
  return arr?.map((_, i) => i);
};

export const calculatePriceWithTax = (tax, price, name) => {
  const totalTax = tax?.Taxes?.reduce((total, tx) => tx.value + total, 0);
  const taxAmount = ((totalTax / 100) * price).toFixed(2);
  let finalPrice = price + Number(taxAmount);
  finalPrice = Number(finalPrice).toFixed(2);
  return { price: finalPrice, for: name };
};

export const maskPhoneNumber = phoneNumber => {
  phoneNumber = phoneNumber?.replace(/\D/g, '');
  const maskedNumber = `(${phoneNumber?.slice(0, 3)})${phoneNumber?.slice(
    3,
    6
  )}-${phoneNumber?.slice(6)}`;
  return maskedNumber;
};

export const convertBase64ToBlobData = base64Data => {
  const contentType = 'image/png';
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const encodedBase64 = value => {
  try {
    return atob(value);
  } catch (error) {
    return '';
  }
};

export const isObjectBlank = obj => {
  if (!obj) {
    return true;
  }
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === '') {
      if (
        // all not required feilds
        key === 'State' ||
        key === 'Country'
      ) {
        continue;
      }
      if (key === 'PostalCode') {
        continue;
      }
      return true;
    }
  }
  return false;
};

export const isObjectBlankForDraft = obj => {
  if (
    (obj?.['package0'] === `Select Package` &&
      obj?.['packageQuantity0'] === 1) ||
    Object.keys(obj).length <= 0
  ) {
    return true;
  }
  for (const key in obj) {
    if (obj[key] === undefined || obj[key] === '') {
      return true;
    }
  }
  return false;
};
