import axios from 'axios';
import { commonConfig } from '../Config/config';
import {
  getCurrentAccessToken,
  logout,
  openNotification,
} from '../Util/commonUtility';
import { JwtExpiryError, commonError } from '../Util/config';
import Urls from '../Constant/urls';

const VNP_API = axios.create({
  baseURL: commonConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// axios request interceptor for JWT header
VNP_API.interceptors.request.use(
  req => {
    const token = getCurrentAccessToken();
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  error => {
    openNotification('error', 'Error', JwtExpiryError);
    setTimeout(() => {
      window.location.replace(`/login`);
    }, 1000);
    throw error;
  }
);

//axios response interceptor for Error handling
VNP_API.interceptors.response.use(
  response => response,
  async err => {
    if (err.response.status === 501) {
      openNotification('error', 'Error', commonError);
    }
    if (err.response.status === 412) {
      //jwt token error
      openNotification('error', 'Error', JwtExpiryError);
      setTimeout(() => {
        window.location.replace(`/login`);
      }, 1000);
      throw err;
    }
    //if (err.response.status === 401) {

    // }
    //  const error = err.response.data.error.message;
    //  openNotification('error', 'Error', error || commonError);
  }
);

export default VNP_API;
