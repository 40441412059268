import axios from 'axios';
import { commonConfig } from '../Config/config';
import {
  getCurrentAccessToken,
  openNotification,
} from '../Util/commonUtility';
import { JwtExpiryError } from '../Util/config';

const NGS_API = axios.create({
  baseURL: commonConfig.ngsUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// axios request interceptor for JWT header
NGS_API.interceptors.request.use(
  req => {
    const token = getCurrentAccessToken();
    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  },
  error => {
    openNotification('error', 'Error', JwtExpiryError);
    setTimeout(() => {
      window.location.replace(`/login`);
    }, 1000);
    // logout();
    throw error;
  }
);

NGS_API.interceptors.response.use(
  response => response,
  err => {
    // if (err.response.status === 501) {
    //   openNotification('error', 'Error', commonError);
    // }
    //   if (err.response.status === 412) {
    //    openNotification('error', 'Error', JwtExpiryError);
    //    logout()
    //    return
    //   }
  }
);

export default NGS_API;
