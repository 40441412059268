import { createAsyncThunk } from '@reduxjs/toolkit';
import VNP_API from '../../../Api/api';
import Urls from '../../../Constant/urls';
import { hideSpinner, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import { setOrderMenuGridList } from '../../Reducers/Order/orderSlice';
import { checkHttpResponse } from '../../../Util/commonUtility';
import { setVnpOrderEntryFields } from '../../Reducers/Address/AddressSlice';
import { commonConfig } from '../../../Config/config';

export const getUser = createAsyncThunk('getuser', async (_, { dispatch }) => {
  const userId =  commonConfig.CUSTOMER_ONTIME_ID;
  try {
    dispatch(showSpinner());
    const response = await VNP_API.get(`${Urls.GET_USER_API}/${userId}`);
    dispatch(hideSpinner());
    if (checkHttpResponse(response)) {
      localStorage.setItem('userId', response?.data?.payload?._id)
      const formFields = response?.data?.payload?.VnpOrderEntryRequiredFields;
      let obj = {};
      
      await formFields.forEach(item => {
        if (item?.Name === 'CollectionLocation') {
          obj.CollectionLocation = item;
        } else if (item?.Name === 'DeliveryLocation') {
          obj.DeliveryLocation = item;
        }
      });
      dispatch(setVnpOrderEntryFields(obj));
      dispatch(
        setOrderMenuGridList(
          JSON.parse(response.data.payload.ColumnStateSaveOrderListShowHide)
        )
      );
    }
  } catch (error) {
    // openNotification('error', 'Error', commonError);
    dispatch(hideSpinner());
  }
});
