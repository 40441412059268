import React from 'react';
import './mainLayout.scss';
import { Layout } from 'antd';
import Navbar from './Navbar/navbar';
const { Content } = Layout;

const MainLayout = props => {

  return (
    <Layout className='layout'>
      <Navbar />
      <Content className='site-layout-content'>
        <div className='site-layout-content-box'>{props.children}</div>
      </Content>
    </Layout>
  );
};

export default MainLayout;
