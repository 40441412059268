import React from 'react';
import './review.scss';
import { calculatePriceWithTax } from '../../../Util/commonUtility';
import { commonConfig } from '../../../Config/config';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { InputMask } from 'primereact/inputmask';

const Review = props => {
  const {
    selectedService,
    tax,
    details,
    packagesArray,
    B2CNotificationFormInstance,
    isOrderSuccessfullyPlaced,
  } = props;
  const { CollectionLocation, DeliveryLocation } = details;

  const { memorizeFieldsValues } = useSelector(state => state.AddressSlice);

  const maskObjForLocation = location => {
    return {
      companyName: `${location?.CompanyName} ${location?.Comments}`,
      addressLine1: location?.AddressLine1,
      place: `${location?.City} ${location?.State || ''} ${
        location?.PostalCode
      }`,
      phone: location?.Phone,
      email: location?.Email,
    };
  };
  const collectionData = maskObjForLocation(CollectionLocation);
  const deliveryData = maskObjForLocation(DeliveryLocation);

  const getPriceBeforeTax = tax => {
    const orignalPrice = selectedService?.quote?.price;
    const taxAmount = ((tax / 100) * orignalPrice).toFixed(2);
    return taxAmount;
  };

  return (
    <div className='review-order-container'>
      <div className='overview-txt-wrapper'>
        <div className='label-wrapper'>
          <span>Overview</span>
        </div>
        <main className='main-container'>
          <div className='details-wrapper'>
            <div className='collection-details-wrapper'>
              <div className='collection-details'>
                <div className='sender-header-container'>Sender </div>
                <span>{collectionData.companyName}</span>
                <span>{collectionData.addressLine1}</span>
                <span> {collectionData.place}</span>
                <span>{collectionData.phone}</span>
                <span>{collectionData.email}</span>
              </div>
              {isOrderSuccessfullyPlaced ? (
                <div className='after-placed-order'>
                  <footer className='notification-config-container'>
                    <div className='sender'>
                      <span>
                        Apartment/floor, Suit:{' '}
                        <p>
                          {
                            memorizeFieldsValues?.B2CNotificationState
                              ?.addressLine2ForSender
                          }
                        </p>
                      </span>
                      <span>
                        Name:{' '}
                        <p>
                          {
                            memorizeFieldsValues.B2CNotificationState
                              ?.SenderName
                          }
                        </p>
                      </span>
                      <span>
                        Email:{' '}
                        <p>
                          {
                            memorizeFieldsValues.B2CNotificationState
                              ?.SenderEmail
                          }
                        </p>
                      </span>
                      <span>
                        Contact number:{' '}
                        <p>
                          {
                            memorizeFieldsValues.B2CNotificationState
                              ?.SenderPhone
                          }
                        </p>
                      </span>
                    </div>
                  </footer>
                </div>
              ) : (
                <Form
                  name='sender-form'
                  className='notify-form'
                  form={B2CNotificationFormInstance}
                >
                  <div className='send-receive-container'>
                    <div className='sender-form-wrapper'>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='addressLine2ForSender'
                          className='notify-config-inputs'
                        >
                          <Input
                            placeholder='Apt/Floor, Suit (Optional)'
                            id='addr2-for-sender'
                            type='text'
                          />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='SenderName'
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please enter your name',
                            },
                          ]}
                        >
                          <Input placeholder='Name' id='name' type='text' />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='SenderEmail'
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your email',
                            },
                            {
                              pattern:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: 'Please enter valid email',
                            },
                          ]}
                        >
                          <Input placeholder='Email' id='email' type='email' />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name={'SenderPhone'}
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your contact number',
                            },
                          ]}
                        >
                          <InputMask
                            mask={'(999)-999-9999'}
                            type='tele'
                            placeholder='Contact Number'
                            className='contact-num-input'
                            id='contact-num'
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </div>
            <div className='delivery-details-wrapper'>
              <div className='delivery-details'>
                <div className='sender-header-container'>Recipient </div>
                <span>{deliveryData.companyName}</span>
                <span>{deliveryData.addressLine1}</span>
                <span> {deliveryData.place}</span>
                <span>{deliveryData.phone}</span>
                <span>{deliveryData.email}</span>
              </div>
              {isOrderSuccessfullyPlaced ? (
                 <div className='after-placed-order'>
                <footer className='notification-config-container'>
                  <div className='recipient'>
                    <span>
                      Apartment/floor, Suit:{' '}
                      <p>
                        {
                          memorizeFieldsValues?.B2CNotificationState
                            ?.addressLine2ForRecipient
                        }
                      </p>
                    </span>
                    <span>
                      Name:{' '}
                      <p>
                        {
                          memorizeFieldsValues.B2CNotificationState
                            ?.RecepientName
                        }
                      </p>
                    </span>
                    <span>
                      Email:{' '}
                      <p>
                        {
                          memorizeFieldsValues.B2CNotificationState
                            ?.RecepientEmail
                        }
                      </p>
                    </span>
                    <span>
                      Contact number:{' '}
                      <p>
                        {
                          memorizeFieldsValues.B2CNotificationState
                            ?.RecepientPhone
                        }
                      </p>
                    </span>
                  </div>
                </footer>
                 </div>
              ) : (
                <div className='send-receive-container'>
                  <Form
                    name='receiver-form'
                    className='notify-form'
                    form={B2CNotificationFormInstance}
                  >
                    <div className='receiver-form-wrapper'>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='addressLine2ForRecipient'
                          className='notify-config-inputs'
                        >
                          <Input
                            placeholder='Apt/Floor, Suit (Optional)'
                            id='addr2-for-rec'
                            type='text'
                          />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='RecepientName'
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please enter your name',
                            },
                          ]}
                        >
                          <Input placeholder='Name' id='name' type='text' />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name='RecepientEmail'
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your email',
                            },
                            {
                              pattern:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: 'Please enter valid email',
                            },
                          ]}
                        >
                          <Input
                            placeholder='Email'
                            id='rec-email'
                            type='email'
                          />
                        </Form.Item>
                      </div>
                      <div className='inputs-wrapper'>
                        <Form.Item
                          name={'RecepientPhone'}
                          className='notify-config-inputs'
                          rules={[
                            {
                              required: true,
                              message: 'Please enter your contact number ',
                            },
                          ]}
                        >
                          <InputMask
                            mask={'(999)-999-9999'}
                            type='tele'
                            placeholder='Contact Number'
                            className='contact-num-input'
                            id='rec-contact-num'
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          </div>
          <div className='table-wrapper'>
            <table className='review-table'>
              <thead>
                <tr>
                  <th colSpan={3}>Packaging Type</th>
                  <th>Length ({commonConfig.HWLUnit})</th>
                  <th>Width ({commonConfig.HWLUnit})</th>
                  <th>Height ({commonConfig.HWLUnit})</th>
                  <th>Quantity</th>
                  <th>Weight ({commonConfig.weightUnit})</th>
                  {/* <th>Unit</th> */}
                </tr>
              </thead>
              <tbody>
                {packagesArray?.map((pkg, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <th colSpan={3} className='package-type'>
                        {pkg.PackagingType}
                      </th>
                      <th>{pkg.Length}</th>
                      <th>{pkg.Width}</th>
                      <th>{pkg.Height}</th>
                      <th>{pkg.Qty}</th>
                      <th>{pkg.Weight}</th>
                    </tr>
                  </React.Fragment>
                ))}
                <tr className='table-bottom'>
                  <th colSpan={7}></th>
                  <th>{}</th>
                </tr>
                <tr className='table-bottom'>
                  <th colSpan={7}>Subtotal</th>
                  <th>$ {selectedService?.quote?.price}</th>
                </tr>
                {tax?.Taxes?.map(tx => (
                  <tr className='table-bottom'>
                    <th colSpan={7}>
                      {tx?.name}:{tx.taxNumber}
                    </th>
                    <th>$ {getPriceBeforeTax(tx.value)}</th>
                  </tr>
                ))}
                <tr className='table-bottom'>
                  <th colSpan={7}>FINAL TOTAL</th>
                  <th>
                    ${' '}
                    {
                      calculatePriceWithTax(tax, selectedService?.quote?.price, '')
                        .price
                    }
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Review;
