import React, { useEffect, useState } from 'react';
import './addressDetails.scss';
import AddressDetailsPickupDeliveryForm from '../../../../Components/Order/Entry/addressDetails/addressDetailsPickupDeliveryForm';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../../Store/Action/user/userAction';

const AddressDetails = ({ form }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      {
        <div
          className='address-details-container'
          style={{ justifyContent: 'space-between' }}
        >
          <AddressDetailsPickupDeliveryForm
            key={'pickupForm'}
            form={form}
            isPickup={true}
          />

          <AddressDetailsPickupDeliveryForm
            key={'deliveryForm'}
            form={form}
            isPickup={false}
          />
        </div>
      }
    </>
  );
};

export default AddressDetails;
