import React from 'react';
import './navbar.scss';
import { redirectTo } from '../../../Util/commonUtility';
import { commonConfig } from '../../../Config/config';


const Navbar = () => {
  return (
    <>
      <nav className='navbar'>
        <div className='nav-links-wrapper'>
          <img
            src={commonConfig.vnpLogo}
            alt=''
            className='nav-logo'
            onClick={redirectTo}
          /> 
        </div>
      </nav>
    </>
  );
};

export default Navbar;
