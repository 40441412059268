import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'Order',
  initialState: {
    orderList: [],
    orderInfo: {},
    deliverySignatures: null,
    collectionSignatures: null,
    ordersColumnList: [],
    ordersTrackingDetails: {},
    orderVisibleGrid: {},
    orderMenuGridList: [],
    packages: [],
    maskedPackages: [],
    packageDescription: '',
    packagesIdArrayInStore: [],
    quoteData: [],
    showQuote: false,
    settings: {},
    report: null,
    attachments: '',
    recalculatedPrice: '',
    quoteSubmitted: {},
    editDraftOrderValues: {},
    quoteApiDataTemp: {}
  },
  reducers: {
    setPackages(state, { payload }) {
      state.packages = payload;
    },
    setPackagesIdArrayInStore(state, { payload }) {
      state.packagesIdArrayInStore = payload;
    },
    setQuoteData(state, { payload }) {
      state.quoteData = payload;
    },
    setSetting(state, { payload }) {
      state.settings = payload;
    },
    setMaskedPackages(state, { payload }) {
      state.maskedPackages = payload;
    },
    setPackageDescription(state, { payload }) {
      state.packageDescription = payload;
    },
    setQuoteSubmitted(state, { payload }) {
      state.quoteSubmitted = payload;
    },
    setShowQuote(state, { payload }) {
      state.showQuote = payload;
    },
    setEditDraftOrderValues(state, { payload }) {
      state.editDraftOrderValues = payload;
    },
    setQuoteApiDataTemp(state, { payload }) {
      state.quoteApiDataTemp = payload;
    }
  },
});

export const {
  setOrderList,
  setOrderInfo,
  setDelivSignatures,
  setColSignatures,
  setAttachments,
  setReport,
  setOrdersTrackingDetails,
  setOrderMenuGridList,
  setPackages,
  setPackagesIdArrayInStore,
  setQuoteData,
  setSetting,
  setMaskedPackages,
  setPackageDescription,
  setRecalculatedPrice,
  setQuoteSubmitted,
  setShowQuote,
  setOrderVisibleGrid,
  setEditDraftOrderValues,
  setQuoteApiDataTemp
} = orderSlice.actions;

export default orderSlice.reducer;
