
module.exports = {
  JwtExpiryError: 'Session expired Please relogin',
  loginValidationError: 'Login Failed',
  commonError: 'Something wents Wrong please refresh the page',
  loginSuccess: 'Login Successful',
  successEditAddress: 'Address Successfully Updated',
  successAddAddress: 'Address Successfully Added',
  successfullyUpdatedFavouriteAddress: 'Favorite location updated successfully',
  errorUpdateAddress: 'Please enter valid Phone Number',
  networkError: 'Please check your internet connection!',
  orderNotFound: 'Order not found with given Tracking Number'
}
